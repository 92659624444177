import React, { useState } from "react"
import useAxios from "axios-hooks"

import Layout from "../components/layout"
import Logo from "../images/vector/default.svg"
import SEO from "../components/seo"
import MoneyButton from "@moneybutton/react-money-button"
import { Input } from "antd"
import styled from "styled-components"

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const Container = styled.div`
  height: 500px;
  flex-wrap: wrap;
  width: 90vw;
  margin: auto;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #eab300;
  flex-direction: column;
`

const Counter = styled.div`
  flex-wrap: wrap;
  flex-direction: column;
  box-sizing: border-box;
  padding: 5px 5px 5px 5px;
  margin: 10px;
  justify-content: center;
  width: 101%;
  border-radius: 6px;
  background: #1f0000;
  box-shadow: 0 2px 6px #000 inset;
  text-align: center;
  margin-right: 15px;
`
/*
const Container = styled.div`
  width: 400px;
  margin: auto;
`
/*
      class="money-button"
      data-to="15195"
      data-amount="0.00001"
      data-currency="BSV"
      data-label=""
      data-client-identifier="b3c294a79cec3fd40cd086e7797b5995"
      data-button-id="1579301696813"
      data-button-data="{}"
      data-type="buy"
*/

const ButtonPage = () => {
  const [email, setEmail] = useState("")
  const [{ data, loading, error }, refetch] = useAxios(
    "https://api.21e8.io/api/status"
  )

  if (loading) {
    return "Loading"
  }

  if (error) {
    return "error"
  }

  if (data && data.s === "true") {
    return "Sold!"
  }

  return (
    <Layout>
      <SEO />
      <Container>
        <Counter>
          <h2>Buy this domain for 10 BSV</h2>
        </Counter>
        <Counter>
          <Input
            style={{
              textAlign: "center",
              backgroundColor: "#740000",
              border: "1px solid #eab300",
              color: " #eab300",
            }}
            placeholder="Enter your e-mail address. You will be contacted on this address."
            size="large"
            onChange={e => {
              setEmail(e.target.value)
            }}
          />
        </Counter>
        {email.match(emailRegex) && (
          <MoneyButton
            to="2061"
            amount={10}
            currency="BSV"
            clientIdentifier="4c749cf65fe33786236fa5644fd21b9e"
            buttonId="1582061386490"
            type="buy"
            buttonData={JSON.stringify({ email })}
          />
        )}
      </Container>
    </Layout>
  )
}

export default ButtonPage
