/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Notifications from "./Notifications"
import Logo from "../images/vector/default-monochrome.svg"
import "./layout.css"

const Header = styled.div`
  position: absolute;
  height: 64px;
  max-height: 64px;
  width: 100%;
  overflow: hidden;
  display: flex;
`

const HeaderLeft = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
`
const HeaderRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
`

const Layout = ({ children }) => {
  return (
    <>
      <Header>
        <HeaderLeft>
          {// <img height="32px" src={Logo} />
          }
        </HeaderLeft>
        <HeaderRight>
          <Notifications />
        </HeaderRight>
      </Header>
      <div
        style={{
          textAlign: "center",
        }}
      >
        {children}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
