import React from "react"

import Layout from "../components/layout"
import Logo from '../images/vector/default.svg'
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO/>
    <img alt="21e8" style={{ height: '95vh'}} src={Logo} />
  </Layout>
)

export default NotFoundPage
