import React from "react"
import { Icon, Tooltip } from "antd"
import styled from "styled-components"

const CustomIcon = styled(Icon)`
  font-size: 32px;
`
const API_DOMAIN = `api.21e8.io`

function urlBase64ToUint8Array(base64String) {
  var padding = "=".repeat((4 - (base64String.length % 4)) % 4)
  var base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

  var rawData = window.atob(base64)
  var outputArray = new Uint8Array(rawData.length)

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}
class Notifications extends React.Component {
  state = {
    permission: null,
    tooltip: "Enable notifications",
  }

  componentWillMount() {
    if (typeof window !== "undefined") {
      this.setState({ permission: window.Notification.permission })
    }
  }

  async askUserPermission() {
    return await Notification.requestPermission()
  }

  async createNotificationSubscription() {
    return navigator.serviceWorker.ready.then(function(registration) {
      return registration.pushManager
        .getSubscription()
        .then(async function(subscription) {
          if (subscription) {
            return subscription
          }

          const response = await fetch(
            `https://${API_DOMAIN}/api/push/vapidPublicKey`
          )
          const vapidPublicKey = await response.text()

          const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey)

          return registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: convertedVapidKey,
          })
        })
    })
  }

  async registerNotificationSubscription() {
    this.createNotificationSubscription().then(subscription => {
      fetch(`https://${API_DOMAIN}/api/push/register`, {
        method: "post",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          subscription: subscription,
        }),
      })
    })
  }

  /*
  async sendNotification() {
    fetch(`https://${API_DOMAIN}/api/push/send`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        subscription: JSON.stringify(await this.createNotificationSubscription()),
        payload: "HELLO",
        delay: 1,
        ttl: 10,
      }),
    });
  }
*/

  async userEnableNotifications() {
    if (this.state.permission === "granted") {
      this.registerNotificationSubscription()
      return
    }
    if (this.state.permission === "denied") {
      this.setState({
        tooltip: "You have disabled notifications at the browser level",
      })
    }

    this.askUserPermission().then(permission => {
      if (permission === "granted") {
        this.setState({ permission: window.Notification.permission })
        this.registerNotificationSubscription()
      }
    })
  }

  renderIcon() {
    if (this.state.permission === "granted") {
      return (
        <CustomIcon
          onClick={() => this.userEnableNotifications()}
          type="bell"
        />
      )
    } else {
      return (
        <Tooltip
          placement="bottomRight"
          title={this.state.tooltip}
          visible={true}
        >
          <CustomIcon
            onClick={() => this.userEnableNotifications()}
            type="bell"
          />
        </Tooltip>
      )
    }
  }

  render() {
    return <>{this.renderIcon()}</>
  }
}

export default Notifications
