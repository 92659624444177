import React from "react"

import Layout from "../components/layout"
import Logo from "../images/vector/default.svg"
import SEO from "../components/seo"
import CountDown from "../components/CountDown"

const IndexPage = () => (
  <Layout>
    <SEO />
    {
      // <img alt="muug" style={{ width: '95vw', height: '95vh'}} src={Logo} />
    }
    {
      //   typeof window !== 'undefined' ? <CountDown expiryTimestamp={new Date('2020-01-03T18:15:05Z').getTime()} /> : ""
    }
    Get notified when old block rewards move.
 
  </Layout>
)

export default IndexPage
