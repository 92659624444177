import React from 'react'
import useTime, { countUp } from './useTime';
import styled from 'styled-components'

const Container = styled.div`
height: 100vh;
width: 100vw;
align-items: center;
display: flex;
justify-content: center;
color: #eab300;
`

const Counter = styled.div`
display: flex;
flex-direction: column;
box-sizing: border-box;
padding: 5px 0 7px;
justify-content: space-around;
width: 52px;
height: 54px;
border-radius: 6px;
background: #1f0000;
box-shadow: 0 2px 6px #000 inset;
text-align: center;
margin-right: 15px;
`;

const Upper = styled.span`
font-size: 24px;
line-height: 24px;
font-weight: 700;
`;

const Lower = styled.span`
font-size: 11px;
line-height: 11px;
text-transform: uppercase;
`;


function CountDown({ expiryTimestamp }) { 
  const {
    seconds,
    minutes,
    hours,
    days 
  } = useTime({ expiryTimestamp });
 
  //1578071705
 
  return (
    <Container>
      <Counter style={{ marginLeft: '15px' }}>
        <Upper>{countUp({ days, hours, minutes, seconds }) ? "+" : "-"}</Upper>
        <Lower>{countUp({ days, hours, minutes, seconds }) ? "plus" : "minus"}</Lower>
      </Counter>      <Counter>
        <Upper>{Math.abs(days) ? Math.abs(days) : "0"}</Upper>
        <Lower>days</Lower>
      </Counter>
      <Counter>
        <Upper>{Math.abs(hours) ? Math.abs(hours) : "0"}</Upper>
        <Lower>hours</Lower>
      </Counter>
      <Counter>
        <Upper>{Math.abs(minutes) ? Math.abs(minutes) : "0"}</Upper>
        <Lower>mins</Lower>
      </Counter>
      <Counter>
        <Upper>{Math.abs(seconds) >= 0 ? Math.abs(seconds) : "0"}</Upper>
        <Lower>secs</Lower>
      </Counter>
    </Container>
  )
}

export default CountDown;